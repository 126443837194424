import { render, staticRenderFns } from "./LeaveRequestEmployeeModal.vue?vue&type=template&id=72082917&scoped=true"
import script from "./LeaveRequestEmployeeModal.vue?vue&type=script&lang=js"
export * from "./LeaveRequestEmployeeModal.vue?vue&type=script&lang=js"
import style0 from "./LeaveRequestEmployeeModal.vue?vue&type=style&index=0&id=72082917&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72082917",
  null
  
)

export default component.exports